import type { ToastEvents, ToastOptions } from '@/composables/types/ui';

type ToastTemplates = 'LOAD_ERROR';

export type ToastStoreItem = {
    bind: ToastOptions,
    on?: ToastEvents,
}

type IToastsState = {
    toasts: ToastStoreItem[],
    closedState: boolean,
}

type IToastsActions = {
    add: (arg0: ToastOptions | ToastTemplates, arg1?: ToastEvents) => number,
    remove: (arg0: number) => void,
    clear: (arg0?: ToastOptions['type']) => void,
    getToasts: () => ToastStoreItem[],
    getToastClosedState: () => boolean,
    setToastClosedState: (flag: boolean) => void,
}

let templates: {
    [_key in ToastTemplates]: any
};

const defineTemplates = () => {
    const { $t } = useNuxtApp();

    templates = {
        LOAD_ERROR: {
            type: 'error',
            headline: $t('General.load_error_title'),
            text: $t('General.load_error_text'),
        }
    };
};

useNuxtApp().hook('app:created', defineTemplates);

export const useToasts = defineStore<string, IToastsState, any, IToastsActions>('toasts', {
    state: () => ({
        toasts: [],
        closedState: false,
    }),
    actions: {
        add(opts: ToastOptions | ToastTemplates, events?: ToastEvents): number {
            if (typeof opts === 'string') {
                this.toasts.push({
                    bind: templates[opts]
                });
            } else {
                this.toasts.push({
                    bind: opts,
                    on: events,
                });
            }

            return this.toasts.length - 1;
        },
        remove(index: number) {
            this.toasts.splice(index, 1);
        },
        clear(type?: ToastOptions['type']) {
            this.toasts = this.toasts.filter(({ bind }) => {
                if (type) {
                    return type !== bind.type;
                }

                return false;
            });
        },
        getToasts(): ToastStoreItem[] {
            return this.toasts;
        },
        getToastClosedState(): boolean {
            return this.closedState;
        },
        setToastClosedState(flag: boolean) {
            this.closedState = flag;
        }
    },
});
